export const footerMenu = {
    "top": [
        {
            "name": "Home",
            "url": "/"
        },{
            "name": "Diskon",
            "url": "/discount"
        },{
            "name": "Tentang",
            "url": "/"
        },{
            "name": "Kontak",
            "url": "/contact"
        }
    ],
    "bottom": [
        [
            {
                "name": "Kategori",
                "url": "/category"
            },{
                "name": "Daftar brand",
                "url": "/brand"
            }
        ],
        [
            {
                "name": "Terbaru",
                "url": "/list/[title]",
                "as": "/list/terbaru"
            },{
                "name": "Terpopuler",
                "url": "/list/[title]",
                "as": "/list/populer"
            },{
                "name": "Akan berakhir",
                "url": "/list/[title]",
                "as": "/list/last-minute"
            }
        ],
        [
            {
                "name": "Syarat ketentuan dan Kebijakan Privasi",
                "url": "/syarat-dan-ketentuan"
            }
        ]
    ]
}
