import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { Router, useRouter, withRouter } from 'next/router';
import Header from './header';
import dynamic from 'next/dynamic';
import { IoIosArrowBack, IoIosSearch, IoIosClose } from 'react-icons/io'
import DisqoninAPI from '@utils/service/api';

import Footer from './footer'
import ShareBottom from './molecules/sharebottom';

const isShowShare = (pathname) => {
  if (pathname === '/discount/[id]' || pathname === '/brand/[id]') {
    return true;
  }
  return false;
};

const Layout = (props) => {
  const router = useRouter()
  const { children = <div />, topTitle = '', hideMenuBar = false } = props;
  const { asPath = '', pathname = '' } = router;
  const url = `https://disqonin.com${asPath}`;

  const [searchKeyword, setSearchKeyword] = useState('')
  const [openSearch, setOpenSearch] = useState(false)

  /* useEffect(() => {
    if (document) {
      const layoutChild = document.getElementsByClassName('child-layout')
      if (layoutChild.length > 0){
        if (openSearch) {
          layoutChild[0].addEventListener("mousedown", () => setOpenSearch(false));
        } else {
          layoutChild[0].removeEventListener("mousedown", () => setOpenSearch(false));
        }
      }
      return () => {
        document.removeEventListener("mousedown", () => setOpenSearch(false));
      };
    }
  }, [openSearch]) */

  const onSearch = async () => {
    /* try {
      const resDiscounts = await DisqoninAPI.discount.searchDiscount(searchKeyword)
      console.log({ resDiscounts })
    } catch (err) {
      console.log(err)
    } */
    router.push({ pathname: '/search/[keyword]', query: { keyword: searchKeyword } });
    setOpenSearch(false)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onSearch()
    }
  }

  return (
    <div className="max-h-screen overflow-y-auto overflow-x-hidden">
      <div id="top" />
      <Head>
        <meta name="application-name" content="Disqonin" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" charSet='utf-8' />
        <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
        <meta name='viewport' content='width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=5' />

        {/* <link rel='manifest' href='/manifest.json' /> */}
      </Head>
      <Header />
      {!hideMenuBar && (
        <div className="flex flex-row h-12 top-0 sticky w-full justify-between items-center px-3 bg-white z-50 shadow-sm lg:h-0 lg:hidden">
          <IoIosArrowBack className="text-black text-xl" onClick={() => router.back()} />
          <p className="w-10/12 whitespace-nowrap overflow-hidden overflow-ellipsis text-center">{topTitle}</p>
          <IoIosSearch className="text-black text-xl" onClick={() => setOpenSearch(true)} />
        </div>
      )}
      <div className={`absolute bottom-full z-50 pt-10 w-full h-10 bg-main-primary transition transform duration-500 ${!openSearch ? '-translate-y-32' : 'translate-y-0'}`}>
        <div className="flex flex-row bg-main-primary pb-2 pt-14">
          <IoIosClose className="text-xl w-12 h-10 text-white" onClick={() => setOpenSearch(false)} />
          <div className="flex flex-row justify-between items-center bg-white flex-1 mr-3 rounded-lg">
            <input
              className="w-full p-2 rounded focus:outline-none"
              placeholder="Cari brand atau diskon disini..."
              value={searchKeyword}
              onChange={e => setSearchKeyword(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <button className="w-20 rounded bg-main-primary text-white h-8 mr-1" onClick={onSearch}>Search</button>
          </div>
        </div>
        <div
          className={`w-full transition ease-in-out duration-500 ${!openSearch ? 'invisible opacity-0' : 'visible opacity-100'}`}
          style={{ height: '88vh', backgroundColor: '#00000052' }} onClick={() => setOpenSearch(false)}
        />
      </div>
      <div className="child-layout">
        {children}
      </div>
      <Footer />
     {/*  {isShowShare(pathname) && (
        <ShareBottom url={url} />
      )} */}
    </div>
  );
};

export default withRouter(Layout);
