/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import Link from 'next/link';
import Router, { withRouter } from 'next/router';
import { isSafari, isIOS, isMobile } from 'react-device-detect';
import LazyLoad from 'react-lazyload';

class HeaderScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerSolid: false
    };
  }

  componentDidMount() {
    if (window) {
      window.addEventListener("scroll", this.handleScroll);
    }
  }

  componentWillUnmount() {
    if (window) {
      window.removeEventListener("scroll", this.handleScroll);
    }
  }

  handleScroll = () => {
    if (window) {
      const { headerSolid } = this.state;
      if (window.pageYOffset > 50 && !headerSolid) {
        this.setState({ headerSolid: true });
      }
      if (window.pageYOffset < 50 && headerSolid) {
        this.setState({ headerSolid: false });
      }
    }
  }

  render() {
    const { router } = this.props;
    const { headerSolid } = this.state;
    const homeClass = router.pathname === '/' ? 'nav-item active' : 'nav-item ';
    const payClass = router.pathname.match(/^\/contact/) ? 'nav-item active' : 'nav-item ';
    // eslint-disable-next-line no-unused-vars
    const brandClass = router.pathname.match(/^\/login-brand/) ? 'nav-item active' : 'nav-item ';
    const disClass = router.pathname.match(/^\/discount/) ? 'nav-item active' : 'nav-item ';

    const isShowPng = isSafari || isIOS;
    const logoUrl = !isMobile ? `/static/img/logo-white-left` : `/static/img/logo-white-left`; 
    return (/* scrolling-navbar fixed-top */
      <nav className={`navbar-expand-lg navbar-light bg-main-primary  ${headerSolid ? 'solid-nav' : ''}`}>
        <div className="container flex flex-row items-center py-2 px-2 justify-between">
          {/* <Button
            type="primary"
            shape="circle"
            icon={ <ArrowLeftOutlined style={{ fontSize: 20, fontWeight: 'bold', position: 'absolute',top: 3, left: 5 }} />}
            style={{ height: 30, width: 25, backgroundColor: '#FD7275', borderColor: 'transparent' }} 
            onClick={() => Router.back()}
          /> */}
          <Link href="/" passHref>
            <a className="navbar-brand">
              <LazyLoad height={32} width={120}>
                <img
                  /* style={{ width: !isMobile ? '120px' : '40px' }}
                  src={`${logoUrl}${isShowPng ? '.png' : '.webp'}`} */
                  style={{ width: '120px', height: 32  }}
                  src={`${logoUrl}${isShowPng ? '.png' : '.webp'}`}
                  alt="Disqonin Promo dan Diskon"
                  title="Disqonin Promo dan Diskon"
                  srcSet={`${logoUrl}${isShowPng ? '.png' : '.webp'}`}
                />
              </LazyLoad>
            </a>
          </Link>
          {/* <Input.Search
            placeholder="Cari..."
            onSearch={value => console.log(value)}
            style={{ flex: 1, marginRight: 10, marginLeft: 15 }}
          /> */}
          <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent-7">
            <ul className="navbar-nav">
              <li className={`${homeClass} mr-5`}>
                <Link href="/" passHref>
                  <a className="nav-link">Home</a>
                </Link>
              </li>
              <li className={`${disClass} mr-5`}>
                <Link href="/discount" passHref>
                  <a className="nav-link">Diskon</a>
                </Link>
              </li>
              <li className={`${payClass} mr-5`}>
                <Link href="/contact" passHref>
                  <a className="nav-link">Kontak</a>
                </Link>
              </li>
              <li className={`${payClass}`}>
                <a
                  className="nav-link pass"
                  href="https://onelink.to/kqsbeh"
                  rel="noreferrer"
                  // eslint-disable-next-line react/jsx-no-target-blank
                  target="_blank"
                  style={{ marginTop: 3, backgroundColor: '#fff', borderRadius: 10, color: '#FF696B', padding: '5px 15px' }}
                >
                  Download
                </a>
              </li>
            </ul>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent-7"
            aria-controls="navbarSupportedContent-7"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
        </div>
      </nav>
    );
  }
}

export default withRouter(HeaderScreen);
