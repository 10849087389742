import React from 'react';
import { MobileOnlyView } from 'react-device-detect';
import {
    FacebookShareButton,
    FacebookMessengerShareButton,
    LineShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    FacebookMessengerIcon,
    WhatsappIcon,
    LineIcon,
    TwitterIcon,
} from 'react-share';

function ShareBottom ({ url = '' }) {
    return (
        <MobileOnlyView>
            <div className="h-14 w-full px-4 bg-main- shadow fixed bottom-0 z-10 flex items-center justify-between" style={{ backgroundColor: '#EFEFEF' }}>
                <p className="d-inline ml-2 mr-2 font-weight-bold">Bagikan: </p>
                <div className="flex flex-row">
                    <FacebookShareButton url={url} quote="Share to Facebook" className="Demo__some-network__share-button ml-1 mr-2 mt-1">
                    <FacebookIcon size={40} className="rounded-md" />
                    </FacebookShareButton>
                    <FacebookMessengerShareButton url={url} quote="Share to Facebook" className="Demo__some-network__share-button ml-2 mr-2">
                    <FacebookMessengerIcon size={40} className="rounded-md" />
                    </FacebookMessengerShareButton>
                    <WhatsappShareButton url={url} quote="Share to Facebook" className="Demo__some-network__share-button ml-2 mr-2">
                    <WhatsappIcon size={40} className="rounded-md" />
                    </WhatsappShareButton>
                    <LineShareButton url={url} quote="Share to Facebook" className="Demo__some-network__share-button ml-2 mr-2">
                    <LineIcon size={40} className="rounded-md" />
                    </LineShareButton>
                    {/* <TwitterShareButton url={url} quote="Share to Facebook" className="Demo__some-network__share-button ml-1 mr-1">
                    <TwitterIcon size={40} round />
                    </TwitterShareButton> */}
                </div>
            </div>
        </MobileOnlyView>
    )
}

export default ShareBottom;
