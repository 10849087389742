import axios from 'axios';

const featured = async () => {
  let featuredData = [];
  try {
    const resFeatured = await axios({
      method: 'POST',
      url: 'https://api.disqonin.com/api/showfeatured',
      data: {
        limit: 5,
      }
    });
    const { status, data = { data: [] } } = resFeatured;
    if (status === 200 && data.status) {
      featuredData = data.data;
      return featuredData;
    }
    throw featuredData;
  } catch (error) {
    console.log({ error });
    throw error;
  }
};

const searchDiscount = async (keyword) => {
  let discountData = [];
  const resSearchDiscount = await axios({
    method: 'GET',
    url: '/api/discount/search',
    params: {
      limit: 5,
      keyword
    }
  });
  const { status, data = [] } = resSearchDiscount || {};
  return data;
};

export default {
  featured,
  searchDiscount
};
