/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import Link from 'next/link';
import { isSafari, isIOS } from 'react-device-detect';
import LazyLoad from 'react-lazyload';
import { footerMenu } from '@utils/data/footerMenu';
import DownloadPlayStore from '@utils/icons/downloadplaystore';
import DownloadAppStore from '@utils/icons/downloadappstore';

const { top, bottom } = footerMenu

class Footer extends PureComponent {
  render() {
    const isShowPng = isSafari || isIOS;
    return (
      <footer className="footer bg-main-bgGreyTransparent p-2 mt-2 lg:p-5 lg:mt-5 position-relative hidden lg:block">
        <div className="container position-relative" style={{ zIndex: 1 }}>
          <div className="row">
            <div className="col-lg-6 text-left my-auto text-light">
              <LazyLoad height={50}>
                <img style={{ width: '200px' }} alt="logo" src={`/static/img/logo-black-left${isShowPng ? '.png' : '.webp'}`} />
              </LazyLoad>
              <p className="text-dark">
                Platform disqon terbaik dan terlengkap se-Indonesia.
                <br /> tersedia di Play Store dan App Store!
              </p>
              <div className="row mb-3" style={{ maxWidth: 150 }}>
                <div className="col-2 col-md-4">
                  <a style={{ cursor: 'pointer' }} aria-label="disqonin instagram" href="https://instagram.com/disqonin" target="_blank" rel="noreferrer">
                    <img src="/static/img/icon/instagram.png" alt="Disqonin instagram" className="shadowgeneral1" style={{ height: 30 }} />
                  </a>
                </div>
                <div className="col-2 col-md-4">
                  <a style={{ cursor: 'pointer' }} aria-label="disqonin facebook" href="https://www.facebook.com/disqonin" target="_blank" rel="noreferrer">
                    <img src="/static/img/icon/facebook.png" alt="Disqonin facebook" style={{ height: 30, marginLeft: 5 }} />
                  </a>
                </div>
              </div>

              <a href="https://bit.ly/disqonin-android" target="_blank" rel="noreferrer" aria-label="Download disqonin play store">
                <DownloadPlayStore width="90" />
              </a>
              <a href="https://onelink.to/kqsbeh" target="_blank" rel="noreferrer" aria-label="Download disqonin app store" className="ml-1">
                <DownloadAppStore width="90" />
              </a>
              <h3 style={{ fontSize: 1 }} className="text-white">
                Tags gopay, gojek, grab, mcdonalds,mcd, diskon, promo, cashback, dana, ovo, bakmi gm, chatime, burger king, xxi, tixid, gotix,
                cinemaxx, cgv, bioskop, hokben, kfc, aw, hop hop, dan lainnya
              </h3>
            </div>
            <div className="col-12 col-lg-6 text-center">
              <div className="row">
                {top.map((item) => (
                  <div className="col-3 col-lg-3 text-left my-auto font-weight-bold text-dark" key={item.name}>
                    <Link href={item.url} passHref>
                      <a className="font-weight-bold text-dark">{item.name}</a>
                    </Link>
                  </div>
                ))}
              </div>
              <div className="row">
                {bottom.map((parent, index) => (
                  <div className="col-3 col-lg-3 text-left mt-3" key={index}>
                    {parent.map((item) => (
                      <div key={item.name} className="mt-2">
                        <Link href={item.url} as={item.as || item.url} passHref>
                          <a className="text-dark">{item.name}</a>
                        </Link>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <img
          style={{
            filter: 'blur(10px)'
          }}
          src="/static/svg/bottom-bg.svg"
          className="position-absolute bottomBg"
          alt="promo-bottom-bg"
        />
      </footer>
    );
  }
}

export default Footer;
